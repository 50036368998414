.progress {
  background-color: $lavander-100;
  height: 6px;
  border-radius: 16px;
  &-blue-2 {
    background-color: $blue-2-100;
  }
  &-label {
    color: $black;
    font-size: 10px;
    font-weight: 700;
  }
  &-bar {
    background-color: $lavander;
    &-blue-2 {
      background-color: $blue-2;
    }
  }
  &-value {
    // position: absolute;
    top: 5px;
    right: 0;
    color: $black;
    font-size: 10px;
  }
  &-rounded {
    width: 48px;
    height: 48px !important;
    float: left;
    line-height: 150px;
    background: none;
    box-shadow: none;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 8px solid $orange-100;
      position: absolute;
      top: 0;
      left: 0;
    }
    .progress-inner {
      // width: 50%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      z-index: 1;

      .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 8px;
        border-style: solid;
        position: absolute;
        top: 0;
        animation: loading-2 1.5s linear forwards 1.8s;
        border-color: $orange;
      }
      &.progress-left {
        left: 0;

        .progress-bar {
          left: 100%;
          border-top-right-radius: 80px;
          border-bottom-right-radius: 80px;
          border-left: 0;
          -webkit-transform-origin: center left;
          transform-origin: center left;
        }
      }
      &.progress-right {
        right: 0;

        .progress-bar {
          left: -100%;
          border-top-left-radius: 80px;
          border-bottom-left-radius: 80px;
          border-right: 0;
          -webkit-transform-origin: center right;
          transform-origin: center right;
          animation: loading-1 1.8s linear forwards;
        }
      }
    }
    .progress-value {
      width: 65%;
      height: 65%;
      border-radius: 50%;
      background: var(--common-white);
      font-size: 10px;
      color: var(--orange);
      line-height: 35px;
      text-align: center;
      position: absolute;
      top: 8px;
      left: 8px;
      font-weight: 700;
      right: auto;
    }
    @keyframes loading-2 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
      }
    }
  }
  &--lg {
    .progress-label {
      font-size: 14px;
    }
    .progress-value {
      font-size: 14px;
      top: 0;
    }
    .progress {
      height: 10px;
    }
  }
  &--md {
    .progress-label {
      font-size: 12px;
    }
    .progress-value {
      font-size: 12px;
      top: 0;
    }
    .progress {
      height: 8px;
    }
  }
}
.CircularProgressbar {
  width: 60px;
  &-trail {
    stroke: $orange-100 !important;
  }
  &-path {
    stroke: $orange !important;
  }
  &-text {
    fill: $orange !important;
    font-weight: 700 !important;
  }
  &.match-rate{
    width: 110px;
    .CircularProgressbar-trail {
      stroke: $common-success-200 !important;
    }
    .CircularProgressbar-path {
      stroke: $common-success !important;
    }
    .CircularProgressbar-text {
      fill: $common-success !important;
    }
  }
}
