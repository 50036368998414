.login-signup {
  &-head {
    height: 50px;

    .logo-container {
      margin-left: -100px;
    }
  }
  &-left {
    &-inner {
      @media screen and (max-width: 1024px) {
        height: calc(100% - 134px) !important;
      }
    }
  }
  &-box {
    &-container {
      // height: calc(100% - 50px);
      // height: 100%;
      margin: auto 0px;
    }
  }
}
