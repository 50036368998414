:root {
  // hexa color code
  --headerInner: #ffffff;
  --sidebar: #275cc6;
  --blueWhite: #275cc6;
  --blackWhite: #ffffff;
  --blackGrey: #f6f9ff;
  --black: #10254f;
  --middleBlack: #475569;
  --greyblack: #e3eaf8;
  --border: #e3eaf8;
  --bodyBg: #f6f9ff;
  --inputBorder: #e3eaf8;
  --inputBg: #ffffff;
  --btnBorder: #e3eaf8;
  --btnSecondary: #f6f9ff;
  --whiteBlackBox: #ffffff;
  --shadowBorder: transparent;
  --light-grey-black: #e2e8f0;
  --light-grey-black-2: #f6f9ff;
  --dropdownBg: #ffffff;
  --trStriped: #fcfdff;
  --editor: #f8fafc;
  --tab-2-border: #b9cbed;
  --blue-2: #1465d8;
  --limegreen: #99c912;
  --lavander: #ac79f6;
  --orange: #e86443;
  --black-2: #081123;
  --lightBlue: #7b9bdc;
  --darklightblue: #7b9bdc;
  --darklightwhite: #ffffff;

  // common hexa color code
  --common-white: #ffffff;
  --common-danger: #f88080;
  --common-success: #56be6c;
  --common-info: #59b0de;
  --common-warning: #f89d46;
  --common-purple: #5b3cce;
  --common-blue: #275cc6;
  --common-grey: #94a3b8;
  --common-light-grey: #cbd5e1;
  --common-shadow: #0b2d790f;

  // common rgba color code
  --common-rgba-white: 255, 255, 255;
  --common-rgba-danger: 248, 128, 128;
  --common-rgba-success: 86, 190, 108;
  --common-rgba-info: 89, 176, 222;
  --common-rgba-warning: 248, 157, 70;
  --common-rgba-purple: 91, 60, 206;
  --common-rgba-blue: 39, 92, 198;
  --common-rgba-grey: 148, 163, 184;

  --rgb-black: 16, 37, 79;
  --rgb-blue-2: 20, 101, 216;
  --rgb-limegreen: 153, 201, 18;
  --rgb-lavander: 172, 121, 246;
  --rgb-orange: 232, 100, 67;
}

// hexa color code
$headerInner: var(--headerInner);
$sidebar: var(--sidebar);
$blueWhite: var(--blueWhite);
$black: var(--black);
$middleBlack: var(--middleBlack);
$greyblack: var(--greyblack);
$border: var(--border);
$bodyBg: var(--bodyBg);
$inputBg: var(--inputBg);
$inputBorder: var(--inputBorder);
$btnBorder: var(--btnBorder);
$btnSecondary: var(--btnSecondary);
$whiteBlackBox: var(--whiteBlackBox);
$shadowBorder: var(--shadowBorder);
$blackWhite: var(--blackWhite);
$blackGrey: var(--blackGrey);
$light-grey-black: var(--light-grey-black);
$light-grey-black-2: var(--light-grey-black-2);
$dropdownBg: var(--dropdownBg);
$trStriped: var(--trStriped);
$editor: var(--editor);
$tab-2-border: var(--tab-2-border);
$blue-2: var(--blue-2);
$limegreen: var(--limegreen);
$lavander: var(--lavander);
$orange: var(--orange);
$black-2: var(--black-2);
$lightBlue: var(--lightBlue);
$darklightblue: var(--darklightblue);
$darklightwhite: var(--darklightwhite);

//===*** Common Variable  ***===//
$common-white: var(--common-white);
$common-danger: var(--common-danger);
$common-success: var(--common-success);
$common-info: var(--common-info);
$common-warning: var(--common-warning);
$common-purple: var(--common-purple);
$common-blue: var(--common-blue);
$common-grey: var(--common-grey);

$common-light-grey: var(--common-light-grey);
$common-shadow: var(--common-shadow);

// common rgba color code
$common-white-100: rgba(var(--common-rgba-white), 0.1);
$common-white-200: rgba(var(--common-rgba-white), 0.2);
$common-white-300: rgba(var(--common-rgba-white), 0.3);
$common-white-400: rgba(var(--common-rgba-white), 0.4);
$common-white-500: rgba(var(--common-rgba-white), 0.5);
$common-white-600: rgba(var(--common-rgba-white), 0.6);
$common-white-800: rgba(var(--common-rgba-white), 0.8);

$common-danger-100: rgba(var(--common-rgba-danger), 0.1);
$common-danger-200: rgba(var(--common-rgba-danger), 0.2);
$common-danger-400: rgba(var(--common-rgba-danger), 0.4);
$common-danger-600: rgba(var(--common-rgba-danger), 0.6);
$common-danger-700: rgba(var(--common-rgba-danger), 0.7);
$common-danger-800: rgba(var(--common-rgba-danger), 0.8);

$common-success-100: rgba(var(--common-rgba-success), 0.1);
$common-success-200: rgba(var(--common-rgba-success), 0.2);
$common-success-400: rgba(var(--common-rgba-success), 0.4);
$common-success-600: rgba(var(--common-rgba-success), 0.6);
$common-success-700: rgba(var(--common-rgba-success), 0.7);
$common-success-800: rgba(var(--common-rgba-success), 0.8);

$common-info-100: rgba(var(--common-rgba-info), 0.1);
$common-info-200: rgba(var(--common-rgba-info), 0.2);
$common-info-400: rgba(var(--common-rgba-info), 0.4);
$common-info-600: rgba(var(--common-rgba-info), 0.6);
$common-info-700: rgba(var(--common-rgba-info), 0.7);
$common-info-800: rgba(var(--common-rgba-info), 0.8);

$common-warning-100: rgba(var(--common-rgba-warning), 0.1);
$common-warning-200: rgba(var(--common-rgba-warning), 0.2);
$common-warning-400: rgba(var(--common-rgba-warning), 0.4);
$common-warning-600: rgba(var(--common-rgba-warning), 0.6);
$common-warning-700: rgba(var(--common-rgba-warning), 0.7);
$common-warning-800: rgba(var(--common-rgba-warning), 0.8);

$common-purple-100: rgba(var(--common-rgba-purple), 0.1);
$common-purple-200: rgba(var(--common-rgba-purple), 0.2);
$common-purple-400: rgba(var(--common-rgba-purple), 0.4);
$common-purple-600: rgba(var(--common-rgba-purple), 0.6);
$common-purple-700: rgba(var(--common-rgba-purple), 0.7);
$common-purple-800: rgba(var(--common-rgba-purple), 0.8);

$common-blue-50: rgba(var(--common-rgba-blue), 0.05);
$common-blue-100: rgba(var(--common-rgba-blue), 0.1);
$common-blue-200: rgba(var(--common-rgba-blue), 0.2);
$common-blue-400: rgba(var(--common-rgba-blue), 0.4);
$common-blue-600: rgba(var(--common-rgba-blue), 0.6);
$common-blue-700: rgba(var(--common-rgba-blue), 0.7);
$common-blue-800: rgba(var(--common-rgba-blue), 0.8);

$common-grey-100: rgba(var(--common-rgba-grey), 0.1);
$common-grey-200: rgba(var(--common-rgba-grey), 0.2);
$common-grey-300: rgba(var(--common-rgba-grey), 0.3);
$common-grey-400: rgba(var(--common-rgba-grey), 0.4);
$common-grey-600: rgba(var(--common-rgba-grey), 0.6);
$common-grey-700: rgba(var(--common-rgba-grey), 0.7);
$common-grey-800: rgba(var(--common-rgba-grey), 0.8);

// rgba color code
$black-300: rgba(var(--rgb-black), 0.3);
$black-400: rgba(var(--rgb-black), 0.4);
$black-600: rgba(var(--rgb-black), 0.6);

$blue-2-100: rgba(var(--rgb-blue-2), 0.12);
$limegreen-100: rgba(var(--rgb-limegreen), 0.12);
$lavander-100: rgba(var(--rgb-lavander), 0.12);
$orange-100: rgba(var(--rgb-orange), 0.12);

//===*** Common Variable  ***===//
// $common-white: #ffffff;
// $common-danger: #f88080;
// $common-success: #56be6c;
// $common-info: #59b0de;
// $common-grey: #94a3b8;
// $common-warning: #f89d46;
// $common-purple: #5b3cce;
// $common-blue: #275cc6;
// $common-shadow: #0b2d790f;

// $white-100: rgba($white, 0.1);
// $white-600: rgba($white, 0.6);
// $primary-100: rgba($primary, 0.1);
// $primary-300: rgba($primary, 0.3);
// $secondary-100: rgba($secondary, 0.1);

//===*** Font Size Variable  ***===//
$font-sizes: 8, 10, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38,
  40, 42, 64;
@each $size in $font-sizes {
  .font--#{$size} {
    font-size: #{$size}px;
  }
}
$font-weight: 200, 300, 400, 500, 600, 700, 800, 900;
@each $size in $font-weight {
  .fw--#{$size} {
    font-weight: #{$size};
  }
}

//===*** Colors Variable  ***===//
$text-colors: ("header-inner", $headerInner), ("sidebar", $sidebar),
  ("black", $black), ("middle-black", $middleBlack), ("black-300", $black-300),
  ("black-400", $black-400), ("black-600", $black-600), ("black-2", $black-2),
  ("grey-black", $greyblack), ("blackWhite", $blackWhite),
  ("white", $common-white), ("white-200", $common-white-200),
  ("light-grey-black", $light-grey-black),
  ("light-grey-black-2", $light-grey-black-2), ("white-400", $common-white-400),
  ("white-500", $common-white-500), ("grey", $common-grey),
  ("grey-100", $common-grey-100), ("grey-600", $common-grey-600),
  ("light-grey", $common-light-grey), ("blue", $common-blue),
  ("blue-white", $blueWhite), ("blue-100", $common-blue-100),
  ("blue-200", $common-blue-200), ("light-blue", $lightBlue),
  ("blue-2", $blue-2), ("info", $common-info), ("info-100", $common-info-100),
  ("purple", $common-purple), ("purple-100", $common-purple-100),
  ("lavander", $lavander), ("limegreen", $limegreen),
  ("limegreen-100", $limegreen-100), ("danger", $common-danger),
  ("danger-200", $common-danger-200), ("warning", $common-warning),
  ("success", $common-success), ("orange", $orange);
@each $text-color in $text-colors {
  //===*** Text Classes  ***===//
  .text--#{nth($text-color,1)} {
    color: nth($text-color, 2);
  }
  //===*** Background Classes  ***===//
  .bg--#{nth($text-color,1)} {
    background-color: nth($text-color, 2);
    // background-color: nth($text-color, 3);
  }
}

//===*** Height Width Variable  ***===//
$height-width-border-radius-sizes: 1, 2, 5, 6, 8, 9, 10, 12, 15, 16, 18, 20, 21,
  24, 25, 27, 30, 32, 36, 40, 45, 46, 49, 50, 55, 60, 64, 70, 75, 76, 80, 85, 90,
  95, 99, 100, 104, 110, 120, 133, 136, 140, 150, 160, 174, 180, 200, 208, 224,
  250, 300, 350, 400, 445, 550, 560, 595, 810;
@each $size in $height-width-border-radius-sizes {
  //===*** Width Classes  ***===//
  .w--#{$size} {
    width: #{$size}px;
  }
  .w--calc-#{$size} {
    width: calc(100% - #{$size}px);
  }
  .w-per--#{$size} {
    width: #{$size}#{"%"};
  }
  .w-flex--#{$size} {
    flex: 1 1 #{$size}#{"%"};
  }
  .w--min-#{$size} {
    min-width: #{$size}px;
  }
  .w--max-#{$size} {
    max-width: #{$size}px;
  }
  .w-per--max-#{$size} {
    max-width: #{$size}#{"%"};
  }
  //===*** Height Classes  ***===//
  .h--#{$size} {
    height: #{$size}px;
  }
  .h--min-#{$size} {
    min-height: #{$size}px;
  }
  .h--max-#{$size} {
    max-height: #{$size}px;
  }
  //===*** Radius Classes  ***===//
  .radius--#{$size} {
    border-radius: #{$size}px;
  }
}
