.form-select {
  background-image: url(../../Assets/Images/selectDropdown.png);
  background-size: 14px 8px;
}
.select-custom {
  &__input-container {
    color: $black !important;
  }
  &__single-value {
    color: $black !important;
  }
  &__control {
    width: 99%;
    margin: 0 auto;
  }
}
// react select dropdown hover css start
// .css-d7l1ni-option{
//   background-color: $common-blue !important;
// }
// .css-1nmdiq5-menu .css-d7l1ni-option{
//   color: $common-white !important;
// }
// react select dropdown hover css end
.css-1dimb5e-singleValue{
  color: $black !important;
}
.css-1cfo1cf{
  color: $black !important;
}
.css-b62m3t-container {
  width: 99%;
  margin: 0 auto;
}
.traning-path-questions{

  .css-b62m3t-container {
    width: 100%;
  }
}
.pac-target-input,
.form-control,
.form-select,
.form-file,
.form-file-selected,
.form-editor {
  // color: $common-light-grey;
  color: $black;
  height: 40px;
  background-color: $inputBg;
  border-width: 1px;
  border-style: solid;
  border-color: $inputBorder;
  padding: 0.375rem 0.75rem;
  border-radius: 6px;
  &:disabled{
    background-color: $inputBg !important;
    cursor: not-allowed !important;
    opacity: 0.7;
  }
  &::marker {
    display: none;
  }
  &::placeholder {
    font-size: 14px;
    color: $common-light-grey;
    font-weight: 400;
  }
  &:focus,
  &:active {
    outline: none;
    color: $black;
    border-color: $common-blue;
    background-color: $inputBg;
    box-shadow: none;
  }
  &.is-invalid {
    border-color: $common-danger !important;
    box-shadow: none !important;
  }
  &-color {
    width: 2rem;
    height: 100%;
    border: none;
    padding: 0;
  }
  &.textarea {
    resize: none;
    height: auto;
    min-height: 40px;
  }
}

.color-picker-control{
  background-color: $inputBg;
  color: $black;  
}
.form-label {
  // font-size: 14px;
  font-weight: 400;
  color: $common-grey;

  &-sm {
    font-size: 12px;
  }
}
.password-icons {
  top: 8px;
  right: 8px;
}
.search-icons {
  bottom: 10px;
  left: 10px;
}
.form-file-container {
  width: fit-content;
  height: 40px;
  @media screen and (max-width: 767px) {
  width: 100%;
  }
  .form-file,
  .form-file-selected {
    min-width: 15%;
    color: $common-blue;
    font-weight: 600;
    background-color: $common-blue-50;
    border-color: $common-blue-100;
    &-container {
      display: flex;
    }
    &-selected {
      &-clip {
        transform: rotate(320deg);
      }
    }
  }
  .form-file {
    width: 100%;
    &-container {
      input {
        width: 100%;
      }
    }
    &--blackgrey {
      background-color: $blackGrey;
    }
  }
}
.input-group-text {
  background-color: $light-grey-black;
}
// .form-editor {
//   padding: 0;
//   overflow: hidden;
//   border-top-left-radius: 0px;
//   border-top-right-radius: 0px;
//   border-color: $inputBorder !important;
//   .ql {
//     &-toolbar.ql-snow,
//     &-container.ql-snow {
//       border: 0;
//     }
//     &-toolbar.ql-snow {
//       background-color: $editor;
//       border-bottom-color: $inputBorder;
//       border-bottom-width: 1px;
//       border-bottom-style: solid;

//       .ql-stroke,
//       .ql-fill {
//         stroke: $common-grey;
//       }
//       button {
//         &:hover {
//           .ql-stroke,
//           .ql-fill {
//             stroke: $common-blue;
//           }
//         }
//       }
//     }
//     &-container.ql-snow {
//       height: 100px;
//     }
//   }
// }
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  width: 15px;
  height: 15px;
  border-width: thin;
  cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url(../../Assets/Images/calendar-event.svg) no-repeat;
  background-size: 100% 100%;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: url(../../Assets/Images/clock.svg) no-repeat;
  background-size: 100% 100%;
  width: 13px;
  height: 13px;
}
.select-custom {
  &__control {
    color: $black;
    height: 40px;
    background-color: $inputBg !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: $inputBorder !important;
    padding: 0 0.75rem;
    border-radius: 6px !important;
    cursor: pointer !important;
  }
  &__value-container {
    padding: 0.375rem 0 !important;
  }
  &__placeholder {
    margin: 0 !important;
    color: var(--black) !important;
  }
  &__input-container {
    margin: 0 !important;
    padding: 0 !important;

    &::after {
      display: none;
    }
  }
  // &__input{
  //   display: none !important;
  // }
  &__indicator-separator {
    display: none;
  }
  &__indicator {
    padding-right: 0 !important;
    color: var(--common-light-grey) !important;

    svg {
      display: inline-block;
      fill: currentColor;
      line-height: 1;
      stroke: currentColor;
      stroke-width: 0;
    }
    &:hover {
      color: var(--common-light-grey) !important;
    }
  }
  &__menu {
    border-radius: 6px !important;
    margin-top: 3px !important;
    box-shadow: none !important;
    background-color: $inputBg !important;
    border-width: 1px;
    border-style: solid;
    border-color: $inputBorder;
  }
  &__menu-list {
    padding: 0 !important;
  }
  &__option {
    cursor: pointer !important;

    &--is-focused {
      background-color: transparent !important;
      color: var(--common-blue) !important;
    }
    &--is-selected {
      background-color: var(--bodyBg) !important;
      color: var(--common-blue) !important;
    }
  }
}
// input[type="radio"] {
//   width: 20px;
//   accent-color: $black;
//   &:checked {
//     content: "";
//     width: 20px;
//   }
// }
// input[type="checkbox"] {
//   width: 20px;
//   accent-color: $black;
//   &:checked {
//     content: "";
//     width: 20px;
//   }
// }

.select-custom {
  &__input {
    min-width: 100px !important;
    grid-area: 1 / 3 !important;
    // grid-area: 1 / 2;
  }
}

// multi select
.css-1jqq78o-placeholder {
  color: $black !important;
}
.css-13cymwt-control {
  background-color: $inputBg !important;
  border-color: $inputBorder !important;
  .css-1jqq78o-placeholder {
    color: $black !important;
  }

  .css-1p3m7a8-multiValue {
    color: $common-blue !important;
    background-color: $blackGrey !important;
    border-color: $common-blue !important;
    .css-9jq23d,
    .css-v7duua {
      color: $common-blue !important;
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.css-t3ipsp-control {
  background-color: $inputBg !important;
  border-color: $inputBorder !important;
  .css-1p3m7a8-multiValue {
    color: $common-blue !important;
    background-color: $blackGrey !important;
    border-color: $common-blue !important;
    .css-9jq23d,
    .css-v7duua {
      color: $common-blue !important;
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}
.form-editor {
  padding: 0;
  overflow: hidden;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: $inputBorder !important;
  min-height: 40px;
}

.ql {
  &-toolbar {
    &.ql-snow {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-color: $inputBorder !important;
      background-color: $editor;
      .ql-stroke {
        stroke: $common-grey;
        &:hover {
          stroke: $common-blue;
        }
      }
      .ql-formats {
        margin-right: 10px !important;
      }
      .ql-fill {
        fill: $common-grey;
        &:hover {
          fill: $common-blue;
        }
      }
    }
  }
}
.post-editor {
  .ql-editor {
    min-height: 100px;
  }
}


  .pac-container{
    // display: block !important;
    z-index: 9999 !important;
  }