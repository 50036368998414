.custom-pagination {
  &-item {
    &:hover{
      color: $common-blue;
    }
    &.active {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        background-color: $light-grey-black;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $common-blue;
        font-weight: 600;
    }
  }
}
