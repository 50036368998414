.custom {
  &-table {
    // min-height: 500px;
    &-height {
      // min-height: calc(100vh - 430px);
      // min-height: 460px;
      min-height: 520px;
    }
    .table {
      &-empty-body {
        // height: calc(100% - 50px);
        min-height: calc(100vh - 420px);
        padding: 2rem;
      }
      &-striped {
        tr:nth-child(even) {
          background-color: $trStriped;
        }
        tr:nth-child(odd) {
          background-color: transparent;
        }
        thead {
          tr {
            th {
              // color: $black;
              color: $middleBlack;
              padding: 0.6rem 0.8rem;
              vertical-align: middle;
              background-color: $trStriped;
              border-color: $light-grey-black;
              white-space: nowrap;
              font-weight: 600;
            }
          }
        }
        tbody {
          tr {
            td {
              // color: $black;
              color: $middleBlack;
              border-color: $light-grey-black;
              padding: 0.6rem 0.8rem;
              vertical-align: middle;
              background-color: transparent;
              box-shadow: none;
              white-space: nowrap;
              // .common-dropdown{
              //   display: none;
              // }
            }
            // &:hover{
            //   .common-dropdown{
            //     display: inline-block;
            //   }
            // }
            &:last-child {
              td {
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
    .add-industry-table{
      td{
        vertical-align: bottom !important;
      }
    }
  }
}
.collapsed-table {
  tr {
    th,
    td {
      white-space: wrap !important;
      &:last-child {
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;
      }
      &:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }
    }
  }
  thead[aria-expanded="false"] {
    th {
      border-bottom: none;
    }
    svg {
      transition: all ease 0.3s;
    }
  }
  thead[aria-expanded="true"] {
    svg {
      transform: rotate(180deg);
      transition: all ease 0.3s;
    }
  }
  thead,
  tbody {
    transition: all ease 0.3s;
  }
  .collapsing {
    border-color: $light-grey-black;
  }
}
