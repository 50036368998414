.common-dropdown {
  .dropdown-menu {
    background-color: $dropdownBg;
    padding: 6px;
    border: 1px solid $shadowBorder;
    box-shadow: 0px 4px 20px 0px $common-shadow;
    min-width: 220px;
    // max-height: 300px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    max-height: 240px;
    overflow-y: auto;
    &.header-inner-dropdown-menu {
      top: 25px !important;
    }
    .dropdown-item {
      font-size: 14px;
      color: $black;
      padding: 8px 12px;

      &:focus,
      &:hover,
      &:active,
      &.active {
        background-color: transparent;
        color: $common-blue;
      }
    }
  }
  .dropdown-toggle {
    font-weight: 500;
    font-size: 14px;
    color: $black;
    &-grey{
      color: $common-grey;
    }
    &.active,
    &:hover,
    &:focus {
      color: $black;
    }
    &::after {
      background-image: url(../../Assets/Images/dropdown-arrow.png);
      width: 12px;
      height: 12px;
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
      position: relative;
      bottom: -8px;
    }
    &.caret-none {
      &::after {
        display: none;
      }
    }
  }
}
// multi select
.css-1nmdiq5-menu {
  background-color: $whiteBlackBox !important;
  .css-d7l1ni-option {
    color: $common-blue !important;
  }
}
