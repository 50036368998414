.leftbar{
.sidebar-menu{
    height: calc(100% - 60px);
   &-item{
    &:hover, &.active{
        color:$common-white;
        background-color: $common-white-300;
        transition: all 0.5s;
        position: relative;
        &::after{
            content: '';
            width: 3px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $common-white;
            display: block;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
   } 
}
}