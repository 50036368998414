@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
html,
body {
  height: 100%;
  font-size: 14px; 
  background-color: $bodyBg;
  color: $black;
  font-weight: normal; 
  // new
  // word-break: break-all;
}
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px $common-grey;
  // background-color: $headerInner;
  -webkit-box-shadow: none;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  background-color: $headerInner;
    @media only screen and (max-width: 767px) {
      width: 2px;
      height: 2px;
  }
}

::-webkit-scrollbar-thumb {
  background:$common-grey;
  border-radius: 100px;
  // @media only screen and (max-width: 767px) {
  //   background: transparent;
  // }
}
