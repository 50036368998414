// desktop header start here
.header {
  &-inner {
    &.active {
      color: $common-white;
      background-color: $common-white-200;
    }
  }
  &-outer {
    &-menu {
      gap: 32px;
    }
    .nav{
      &-link{
        &:focus, &:hover{
          color: $common-blue !important;
        }
      }
    }
  }
}
// mobile bottom menu tab start here
.mobile-bottom-menu{
 &-tab{
  min-height: 65px;
 }
    &-item{
        color: $common-grey;
        min-width: 85px;
        &:hover,
        &.active {
          color: $common-blue;
        }
    }
}
// mobile header start here
.mobile-menu-active {
  .mobile-menu-header {
    left: 0px;
    transition: all 0.3s ease-in-out;
  }
}
.mobile-menu {
  &-header {
    transition: all 0.3s ease-in-out;
    left: -1025px;
  }
  &-items {
    color: $common-grey;
    &:hover,
    &.active {
      color: $common-blue;
    }
  }
}
