.blur-effect {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}
.cursor {
  &--pointer {
    cursor: pointer;
  }
  &--notallowed {
    cursor: not-allowed;
  }
}
.d-style {
  box-shadow: none;
  outline: none;
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }
}
.outline {
  &-none {
    outline: none;
  }
}
.shadow {
  box-shadow: 0px 4px 20px 0px $common-shadow !important;
  &-border {
    border: 1px solid $shadowBorder;
    box-shadow: 0px 4px 20px 0px $common-shadow;
  }
}
.transform {
  &-180 {
    transform: rotate(180deg);
  }
}
.box {
  background-color: $whiteBlackBox;
}
.view-all {
  color: $common-grey;
  cursor: pointer;
  &:hover {
    color: $common-blue;
  }
}
.upload-image {
  &:hover {
    .upload-image-icon {
      display: flex !important;
    }
  }
}
.multi-color-bar {
  &-item {
    &:first-child {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }
    &:last-child {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }
}
.top {
  &---5 {
    top: -5px;
  }
  &--5 {
    top: 5px;
  }
  &--7 {
    top: 7px;
  }
  &--10 {
    top: 10px;
  }
  &--40 {
    top: 40px;
  }
}
.bottom {
  &--5 {
    bottom: 5px;
  }
  &--n3 {
    bottom: -3px;
  }
  &--10 {
    bottom: 10px;
  }
}
.end {
  &--5 {
    right: 5px;
  }
  &--10 {
    right: 10px;
  }
}
.start {
  &--5 {
    left: 5px;
  }
  &--10 {
    left: 10px;
  }
}
.grid {
  &--row {
    &-3 {
      grid-template-rows: repeat(3, 1fr);
    }
  }
  &--col {
    &-3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
.no-assessment-found {
  min-height: calc(100vh - 255px);
}
.w {
  &-fit-content {
    width: fit-content;
  }
  &-xs {
    &-100 {
      @media screen and (max-width: 767px) {
        width: 100% !important;
      }
    }
  }
  &-md {
    &-100 {
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }
    }
  }
  &--max {
    &-xs-full {
      @media screen and (max-width: 767px) {
        max-width: 100% !important;
      }
    }
    &-md-full {
      @media screen and (max-width: 1024px) {
        max-width: 100% !important;
      }
    }
  }
}
.h {
  &--min {
    &-xs-auto {
      @media screen and (max-width: 767px) {
        min-height: auto !important;
      }
    }
  }
}
// gallery post grid
.gallery {
  &--item {
    &-1 {
      grid-template-columns: repeat(1, 1fr);
    }
    &-2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &-3 {
      grid-template-columns: repeat(2, 1fr);
      .post-gallery-item {
        &:first-child {
          grid-column: span 2;
        }
      }
    }
    &-4 {
      grid-template-columns: repeat(2, 1fr);
    }
    &-5 {
      grid-template-columns: repeat(6, 1fr);
      .post-gallery-item {
        grid-column: auto / span 2;
        &:nth-child(1),
        &:nth-child(2) {
          grid-column: auto / span 3;
        }
      }
    }
  }
}
// contact us
.contact-us-section {
  .left-sec {
    width: 40%;
    @media screen and (max-width: 1024px) {
      width: 100%;
      border-right: 0px !important;
    }
  }

  .right-sec {
    width: 70%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
// latest post
.post-gallery {
  &-item {
    img {
      @media screen and (max-width: 767px) {
        height: 120px !important;
      }
    }
    .react-loading-skeleton {
      @media screen and (max-width: 767px) {
        height: 120px !important;
      }
    }
  }
}

.page-content {
  height: 100%;

  .page-body {
    &-box {
      min-height: 100%;
    }
  }
  // height: calc(100% - 68px);
  // @media only screen and (max-width:767px) {
  //   height:100%;
  // }
  .page-body {
    // &.users-page-body{
    //   height: calc(100% - 160px);
    //   @media only screen and (max-width:767px) {
    //     height: calc(100% - 130px);
    //   }
    // }
    // &.organisations-page-body{
    //   height: calc(100% - 100px);
    //   @media only screen and (max-width:767px) {
    //     height: calc(100% - 70px);
    //   }
    // }
    .page-wrapper-inner {
      .left-area {
        width: calc(100% - 350px) !important;
        @media screen and (max-width: 1024px) {
          width: 100% !important;
        }
      }
      .right-area {
        @media screen and (max-width: 1024px) {
          max-width: 100%;
        }
      }
    }
    .page-wrapper-setting {
      .left-area {
        @media screen and (max-width: 1024px) {
          max-width: 240px;
        }
        @media screen and (max-width: 767px) {
          max-width: 100%;
        }
      }
      .right-area {
        @media screen and (max-width: 1024px) {
          width: calc(100% - 260px) !important;
        }
        @media screen and (max-width: 767px) {
          width: 100% !important;
        }
      }
    }
    .page-wrapper-fliter {
      .left-area {
        @media screen and (max-width: 1024px) {
          max-width: 100%;
        }
        .filter-box {
          @media screen and (max-width: 1024px) {
            position: absolute;
            left: -1000px;
            top: 0px;
            height: 100%;
            transition: all 0.5s;
            border-radius: 0px !important;
            z-index: 999;
            &.active {
              left: 0px;
              transition: all 0.5s;
            }
          }
        }
      }
      .right-area {
        width: calc(100% - 350px) !important;
        @media screen and (max-width: 1024px) {
          width: 100% !important;
        }
      }
    }
  }
}

.text-ellipse {
  text-overflow: ellipsis;
  overflow: hidden;
  &-1 {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &-2 {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &-3 {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &-4 {
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.hint {
  font-size: 11px;
}

/* Basic styles for the tooltip container */
.react-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  // margin: 50px;
}

/* Hidden by default */
.react-tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the tooltip container */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0; /* Initially transparent */
  transition: opacity 0.3s; /* Fade-in effect */
}

/* Arrow */
.react-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%; /* Bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when hovering */
.react-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1; /* Fully opaque */
}

.droppable {
  background: #f8f9fa;
  padding: 20px;
  width: 300px;
  margin: 0 auto;
}

.draggable {
  user-select: none;
  padding: 16px;
  margin: 0 0 8px 0;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.draggable:hover {
  background: #f1f1f1;
}

.icons {
  display: flex;
  gap: 8px;
}

.drag-icon {
  cursor: grab;
}

.delete-icon {
  cursor: pointer;
  color: red;
}

.add-option {
  padding: 16px;
  color: #adb5bd;
  text-align: center;
  cursor: pointer;
}
.text-danger {
  color: $common-danger !important;
}
// calendar
.MuiPickersArrowSwitcher-root {
  margin-right: -12px !important;
}
.MuiDateCalendar-root {
  width: 100% !important;
  height: 320px !important;
  margin-bottom: 5px !important;
  .MuiPickersCalendarHeader-root {
    padding-left: 12px !important;
    .MuiButtonBase-root {
      .MuiSvgIcon-root {
        color: $common-grey !important;
      }
    }
  }
  .MuiPickersFadeTransitionGroup-root {
    .MuiDayCalendar-header {
      justify-content: space-between !important;
      .MuiTypography-root {
        width: 40px !important;
        font-weight: 700 !important;
        color: $common-grey !important;
      }
    }
    .MuiPickersSlideTransition-root {
      .MuiDayCalendar-weekContainer {
        justify-content: space-between !important;
        .MuiButtonBase-root {
          width: 40px !important;
          height: 40px !important;
          color: $black;
          &.MuiPickersDay-root {
            &.MuiPickersDay-root {
              border-radius: 8px !important;
              &.MuiPickersDay-today {
                border: none !important;
                background: $black !important;
                color: $whiteBlackBox !important;
                border-radius: 8px !important;
              }
              &.Mui-selected {
                border: none !important;
                background: $common-blue !important;
                color: $common-white !important;
              }
            }
          }
        }
      }
    }
  }
}

// swiper slider start here
.swiper {
  &-button-prev {
    color: $common-blue;
    height: 40px;
    width: 40px;
    border: 1px solid $common-blue;
    border-radius: 100px;
    &::after {
      font-size: 22px;
    }
  }
  &-button-next {
    color: $common-blue;
    height: 40px;
    width: 40px;
    border: 1px solid $common-blue;
    border-radius: 100px;
    &::after {
      font-size: 22px;
    }
  }
}

.page-break {
  page-break-after: always;
}
.ql-editor {
  padding: 5px !important;
  ol,
  ul {
    padding-left: 0px !important;
  }
}
.ql-container {
  font-size: 14px !important;
}

div.cover-letter-body.ql-editor {
  white-space: normal !important;
  padding: 0px !important;
}

// calendar
.tui-full-calendar {
  &-layout {
    border-radius: 10px;
    // background-color: $whiteBlackBox !important;
  }
  &-month-dayname {
    border-top: 0px !important;
  }
  // &-month-dayname-item {
  //   span {
  //     color: $black !important;
  //   }
  // }
  // &-weekday-grid-date {
  //   color: $black !important;
  // }
}
.scroll-gap {
  padding-right: 5px;
  margin-right: -5px;
}
.custom-file-viwer {
  .pg-viewer-wrapper {
    margin: 30px auto !important;
  }
  .pdf-viewer {
    &-container {
      text-align: center !important;
    }
  }
  .pdf-canvas {
    canvas {
      @media screen and (max-width: 1024px) {
        width: 100% !important;
      }
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  // margin: 0;
}

// input[type="number"] {
//   -moz-appearance: textfield; /* Hides the spinner in Firefox */
// }
.year-calendar {
  // &-card{
  //   height: 350px !important;
  // }
  // .tui-full-calendar-month{
  //   min-height: auto !important;
  // }
  .tui-full-calendar-vlayout-container {
    border: 1px solid #e4e4f2;
    border-radius: 6px;
  }
  .tui-full-calendar-extra-date {
    .tui-full-calendar-weekday-grid-header {
      display: none !important;
    }
  }
}
.path-daigram {
  .complete-box {
    &::before {
      content: "";
      width: 1px;
      height: 40px;
      border-right: 1px solid $common-success;
      display: block;
      position: absolute;
      bottom: -42px;
      right: 50%;
    }
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      background-color: $common-success;
      display: block;
      position: absolute;
      bottom: -3px;
      right: 49%;
      transform: rotate(45deg);
    }
    &-inner {
      &::after {
        content: "";
        width: 8px;
        height: 8px;
        background-color: $common-success;
        display: block;
        position: absolute;
        bottom: -60px;
        right: 49%;
        transform: rotate(45deg);
      }
    }
    
    &.current-box-hide{
      margin-bottom: 43px;
      &::after {
        display: none;
      }
      .complete-box-inner{
        &::after{
          display: none;
        }
      }
    }
  }
  .current-box {
    // &::after {
    //   content: "";
    //   width: 8px;
    //   height: 8px;
    //   background-color:$common-success;
    //   display: block;
    //   position: absolute;
    //   top: -5px;
    //   right: 49%;
    //   transform: rotate(45deg);
    // }
    &::before {
      content: "";
      width: 1px;
      height: 40px;
      border-right: 1px solid $common-success;
      display: block;
      position: absolute;
      bottom: -42px;
      right: 50%;
      // @media screen and (max-width: 1024px) {
      //   display: none;
      // }
    }
    &.last-item {
      &::before {
        border-right: 1px solid $darklightblue;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
    }
  }
  &-step {
    &-1 {
      position: relative;
      &::before {
        content: "";
        width: 111%;
        height: 1px;
        border-bottom: 1px dashed $common-danger;
        display: block;
        position: absolute;
        top: -43px;
        left: 50%;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
      .path-daigram-card {
        &::before {
          content: "";
          width: 1px;
          height: 40px;
          border-right: 1px dashed $common-danger;
          display: block;
          position: absolute;
          top: -42px;
          left: 50%;
        }
      }
    }
    &-2 {
      .path-daigram-card {
        &::before {
          content: "";
          width: 1px;
          height: 43px;
          border-right: 1px solid $darklightblue;
          display: block;
          position: absolute;
          top: -43px;
          right: 50%;
        }
      }
    }
    &-3 {
      position: relative;
      &::before {
        content: "";
        width: 111%;
        height: 1px;
        border-bottom: 1px dashed $lavander;
        display: block;
        position: absolute;
        top: -43px;
        right: 50%;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
      .path-daigram-card {
        &::before {
          content: "";
          width: 1px;
          height: 40px;
          border-right: 1px dashed $lavander;
          display: block;
          position: absolute;
          top: -42px;
          left: 50%;
        }
      }
    }
  }
  &-mobile-step {
    &-1 {
      position: relative;
      &::before {
        content: "";
        width: 111%;
        height: 1px;
        border-bottom: 1px dashed $common-danger;
        display: block;
        position: absolute;
        top: -43px;
        left: 50%;
        @media screen and (max-width: 1180px) {
          display: none;
        }
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
      .path-daigram-card {
        &::before {
          content: "";
          width: 1px;
          height: 40px;
          border-right: 1px solid $darklightblue;
          display: block;
          position: absolute;
          top: -42px;
          right: 50%;
        }
        &:first-child {
          &::before {
            display: none;
          }
        }
      }
    }
    &-2 {
      .path-daigram-card {
        &::before {
          content: "";
          width: 1px;
          height: 43px;
          border-right: 1px dashed $lavander;
          display: block;
          position: absolute;
          top: -43px;
          left: 50%;
        }
        &:first-child {
          &::before {
            display: none;
          }
        }
      }
    }
    &-3 {
      position: relative;
      &::before {
        content: "";
        width: 111%;
        height: 1px;
        border-bottom: 1px dashed $lavander;
        display: block;
        position: absolute;
        top: -43px;
        right: 50%;
        @media screen and (max-width: 1180px) {
          display: none;
        }
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
      .path-daigram-card {
        &::before {
          content: "";
          width: 1px;
          height: 40px;
          border-right: 1px dashed $common-danger;
          display: block;
          position: absolute;
          top: -42px;
          left: 50%;
        }
        &:first-child {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
.Toastify__toast-container {
  @media screen and (max-width: 767px) {
    width: 95% !important;
    left: 0 !important;
    right: 0 !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
.poll-search-head {
  @media screen and (max-width: 767px) {
    .w--max-350 {
      max-width: 100%;
    }
  }
}
.cv-pdf-wrapper {
  @media screen and (max-width: 767px) {
    svg {
      width: 12px !important;
      height: 12px !important;
    }
    p {
      font-size: 10px !important;
    }
    span {
      font-size: 10px !important;
    }
    .font--14 {
      font-size: 10px;
    }
    .font--16 {
      font-size: 10px;
    }
    .font--18 {
      font-size: 12px;
    }
    .font--24 {
      font-size: 14px;
    }
    .font--18 {
      font-size: 12px;
    }
    .w--max-300 {
      max-width: 100px !important;
    }
    .badge {
      padding: 4px 4px !important;
    }
    ul {
      li {
        font-size: 10px !important;
      }
    }
  }
}
// cv cover letter css start here
.cover-wraper-outer {
  p {
    span {
      color: $middleBlack !important;
    }
  }
  @media screen and (max-width: 767px) {
    svg {
      width: 12px !important;
      height: 12px !important;
    }
    p {
      font-size: 10px !important;
    }
    .font--12 {
      font-size: 10px;
    }
    .font--16 {
      font-size: 14px;
    }
    ul {
      li {
        font-size: 10px !important;
      }
    }
  }
}
// cv pd letter css start here
.cv-wrapper-outer {
  p {
    span {
      color: $middleBlack !important;
    }
  }

  .badge {
    // font-size: 10px;
    padding: 6px 6px !important;
  }
  @media screen and (max-width: 767px) {
    .left-area {
      max-width: 100px !important;
    }
    .right-area {
      width: calc(100% - 100px) !important;
    }
    svg {
      width: 12px !important;
      height: 12px !important;
    }
    span {
      font-size: 10px;
    }
    p {
      font-size: 10px !important;
      // word-wrap: break-word;
      word-break: break-all;
    }
    .font--14 {
      font-size: 10px;
    }
    .font--12 {
      font-size: 10px;
      word-break: break-all;
    }
    .font--18 {
      font-size: 14px;
    }
    .badge {
      padding: 4px 4px !important;
    }
    ul {
      li {
        font-size: 10px !important;
      }
    }
  }
}

// rdp calendar
.rdp {
  &-months {
    min-width: 100%;
  }
  &-month {
    width: 100%;
    &_grid {
      width: 100%;
    }
  }
  &-chevron {
    fill: $common-grey !important;
    width: 20px;
    height: 20px;
  }
  &-weeks {
    .rdp-week {
      .rdp-day {
        border-radius: 6px;
        .rdp-day_button {
          text-align: center;
          width: 100%;
          color: $black;
        }
      }
    }
    .rdp-selected {
      .rdp-day_button {
        border-radius: 6px;
        border: 2px solid #e35284;
        background-color: #fb8eb3;
      }
    }
  }
}
.month-calendar {
  table {
    border-spacing: 2px;
    border-collapse: separate;
  }
}
.year {
  &-calendar {
    table {
      border-spacing: 2px;
      border-collapse: separate;
    }
    .rdp-month_caption {
      @media screen and (max-width: 1024px) {
        height: auto;
      }
    }
    .rdp-caption_label {
      font-size: 14px;
      @media screen and (max-width: 767px) {
        font-size: 8px;
      }
    }
    .rdp-day {
      font-size: 12px;
      @media screen and (max-width: 1024px) {
        height: auto;
      }
      @media screen and (max-width: 767px) {
        font-size: 6px;
        border-radius: 3px !important;
      }
      .rdp-day_button {
        @media screen and (max-width: 1024px) {
          height: auto;
        }
        @media screen and (max-width: 767px) {
          border-radius: 3px;
        }
      }
    }
    .rdp-weekday {
      font-size: 12px;
      @media screen and (max-width: 767px) {
        font-size: 6px;
        height: auto;
      }
    }
  }
}

// .range {
//   width: 100%;
//   height: 10px;
//   -webkit-appearance: none;
//   background: #111;
//   outline: none;
//   border-radius: 15px;
//   // overflow: hidden;
//   box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);
// }
// .range::-webkit-slider-thumb {
//   -webkit-appearance: none;
//   width: 15px;
//   height: 15px;
//   border-radius: 50%;
//   background: #00fd0a;
//   cursor: pointer;
//   border: 2px solid #333;
//   box-shadow: -100% 0 0 10% #00fd0a;
// }

.application-steps {
  &--circle {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: $common-grey-300;
      top: 48%;
      // left: 59%;
      left: 56%;
      @media screen and (max-width: 992px) {
        top: 62%;
        left: 8%;
        height: 100%;
        width: 4px;
      }
    }
    &.current-line {
      &::after {
        background-color: $common-blue;
        transition: 0.3s cubic-bezier(0.65, 0, 0.35, 1);
      }
    }
  }
  &--item {
    &:last-child {
      .application-steps--circle {
        &::after {
          display: none;
        }
      }
    }
  }
}
