.carousel{
    &-control-prev{
        top: auto;
        bottom: 105%;
        right: 53px;
        left: auto;
        width: 40px;
        height: 40px;
        border-width: 1px;
        border-style: solid;
        border-color: $common-blue;
        border-radius: 50%;
        @media only screen and (max-width: 767px){
            bottom: -50px;
            left: 0px;
        }
        &-icon{
            background-image: url(../../Assets/Images/chevron-left.svg);
            background-size: 100% 100%;
        }
    }
    &-control-next{
        top: auto;
        bottom: 105%;
        right: 0;
        left: auto;
        width: 40px;
        height: 40px;
        border-width: 1px;
        border-style: solid;
        border-color: $common-blue;
        border-radius: 50%;
        @media only screen and (max-width: 767px){
            bottom: -50px;
        }
        &-icon{
            background-image: url(../../Assets/Images/chevron-right.svg);
            background-size: 100% 100%;
        }
    }
    &-indicators{
        bottom: -55px;
        gap: 10px;
        
        button[data-bs-target]{
            background-color: $greyblack;
            width: 8px;
            height: 8px;
            border-radius: 50%;

            &.active{
                background-color: $lightBlue;
            }
        }
    }
}