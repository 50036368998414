.badge{
    font-size: 12px;
    font-weight: normal;
    border: 1px solid transparent;
    white-space: pre-wrap;
    // line-height: 17px;
    &--white-100{
        color: $common-white;
        background-color: $common-white-100;
        border-color: $common-white-100;
    }
    &--danger{
        color: $common-danger;
        background-color: $common-danger-100;
        border-color: $common-danger;
    }
    &--blue{
        color: $common-white;
        background-color: $common-blue;
        border-color: $common-blue;
    }
    &--success{
        color: $common-success;
        background-color: $common-success-100;
        border-color: $common-success;
    }
    &--warning{
        color: $common-warning;
        background-color: $common-warning-100;
        border-color: $common-warning;
    }
    &--purple{
        color: $common-purple;
        background-color: $common-purple-100;
        border-color: $common-purple;
        color: $common-purple;
    }
    &--info{
        color: $common-info;
        background-color: $common-info-100;
        border-color: $common-info;
    }
    &--grey-outline{
        color: $common-grey;
        background-color: $common-grey-100;
        border-color: $common-grey;
    }
    &--grey{
        color: $common-grey;
        background-color: $common-grey-100;
        border-color: none;
    }
    &--grey-2{
        color: $common-grey;
        background-color: $editor;
        border-color: $editor;
    }
    &--blue-2{
        color: $blue-2;
        background-color: $blue-2-100;
        border-color: transparent;
    }
    &--limegreen{
        color: $limegreen;
        background-color: $limegreen-100;
        border-color: transparent;
    }
    &--secondary{
        color: $common-blue;
        background-color: $blackGrey;
        border-color: $common-blue;
    }
    &--orange{
        color: $orange;
        background-color: $orange-100;
        border-color: transparent;
    }
    &--limegreen{
        color: $limegreen;
        background-color: $limegreen-100;
        border-color: transparent;
    }
    &--lavander{
        color: $lavander;
        background-color: $lavander-100;
        border-color: $lavander;
    }
    &--blue-darkLight{
        color: $darklightblue;
        background-color: $darklightwhite;
        border-color: $darklightblue;
    }
}