.outer-layout {
  // background-color: $whiteBlackBox;

  .banner {
    // background: url(../../Assets/Images/bg-landing.svg) no-repeat;
    // background-size: 100% auto;
    // background-position: bottom;
    // @media only screen and (max-width: 1024px){
    // min-height: calc(100% - 70px);
    // }

    // old
    // height: 630px;
    @media only screen and (max-width: 1024px) {
      &.h--min-550 {
        min-height: auto !important;
      }
    }
    &-content {
      // old
      // height: calc(100% - 40px - 2rem);
      // padding-left: 15.5%;
      // @media only screen and (max-width: 1550px){
      //     padding-left: 7%;
      // }
      // old
      // new
      // @media only screen and (max-width: 767px){
      //     padding-left: 0%;
      //    .h--min-560{
      //     min-height: auto;
      //    }
      // }
      // new
      @media only screen and (max-width: 1024px) {
        .h--max-550 {
          max-height: 350px !important;
        }
        .h--min-550 {
          min-height: 350px !important;
        }
      }
      @media only screen and (max-width: 767px) {
        .h--max-550 {
          max-height: 250px !important;
        }
        .h--min-550 {
          min-height: 250px !important;
        }
      }
    }
    &-skeleton-span {
      min-height: 550px !important;
      // new
      @media only screen and (max-width: 1550px) {
        //   min-height: 550px !important;
        // height: auto !important;
      }
      @media only screen and (max-width: 1024px) {
        min-height: 350px !important;
      }
      @media only screen and (max-width: 767px) {
        min-height: 250px !important;
      }
      span {
        // height: 630px !important;
        min-height: 550px !important;
        @media only screen and (max-width: 1024px) {
          min-height: 350px !important;
        }
        @media only screen and (max-width: 767px) {
          min-height: 250px !important;
        }
        // new
        // @media only screen and (max-width: 1550px) {
        //   height: auto !important;
        // }
      }
    }
  }
}
