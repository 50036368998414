@import './_variables.scss';
@import './_base.scss';

// components scss
@import "../Styles/components/commonUse.scss";
@import "../Styles/components/dropdown.scss";
@import "../Styles/components/formControl.scss";
@import "../Styles/components/formCheck.scss";
@import "../Styles/components/button.scss";
@import "../Styles/components/customTabs.scss";
@import "../Styles/components/header.scss";
@import "../Styles/components/sidebarMenu.scss";
@import "../Styles/components/border.scss";
@import "../Styles/components/table.scss";
@import "../Styles/components/pagination.scss";
@import "../Styles/components/modal.scss";
@import "../Styles/components/badges.scss";
@import "../Styles/components/loader.scss";
@import "../Styles/components/progressBar.scss";
@import "../Styles/components/formRange.scss";
@import "../Styles/components/spacing.scss";
@import "../Styles/components/carousel.scss";
@import "../Styles/components/collapse.scss";

// pages scss
@import "../Styles/pages/login.scss";
@import "../Styles/pages/landing.scss";
@import 'react-loading-skeleton/dist/skeleton.css'

// @import "../Styles/components/customAnimations";

//Outer Layout  scss


//Inner Layout  scss