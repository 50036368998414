.modal {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    @media only screen and (max-width: 767px) {
    background-color: $whiteBlackBox;
    }
  &-dialog  {
    @media only screen and (max-width: 767px) {
        background-color: $whiteBlackBox;
        }
    @media only screen and (max-width: 767px) {
      // height: 100vh;
      height: 100%;
      margin: 0px;
      // new
      width: 100%;
      max-width: 100%;
    }
  }
  &-content {
    background-color: $whiteBlackBox;
    border-radius: 12px;
    padding: 32px;
    overflow: hidden;
    
    @media only screen and (max-width: 767px) {
      border-radius: 0px;
      background-clip: unset;
      // height: 100vh;
      height: 100%;
      padding: 20px !important;
      &.progressbar-modal{
        padding: 0px !important;
        .modal-body{
          padding: 20px !important;
        }
      }
    }
  }
  &-xxl{
    max-width: 90%;
  }
  &-header{
    padding: 0;
    border-bottom: 0;

    .btn-close{
      opacity: .2;
    }
  }
  &-title{
    font-weight: 700;
    // font-size: 24px;
    @media only screen and (max-width: 767px) {
      // font-size: 22px;
      }
  }
  &-body{
    padding-left: 0;
    padding-right: 0;
    padding-top: 1.5rem;
    @media only screen and (max-width: 767px) {
    // height: calc(100% - 125px);
    height: calc(100% - 95px);
    &.modal-scroll-gap{
      padding-right: 5px;
      margin-right: -5px;
    }
    }
    &.modal-body-with-head{
      @media only screen and (max-width: 767px) {
      height: calc(100vh - 70px);
      }
    }
  }
  &-footer{
    border-top: 0;
    padding: 0;
    margin-top: 1.5rem;
  }
}
