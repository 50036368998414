.border{
    border-color: $common-grey !important;
    &--grey-black{
        border-color: $greyblack !important;
    }
    &--grey{
        border-color: $common-grey !important;
    }
    &--grey-200{
        border-color: $common-grey-200 !important;
    }
    &--grey-400{
        border-color: $common-grey-400 !important;
    }
    &--light-grey{
        border-color: $common-light-grey !important;
    }
    &--light-grey-black{
        border-color: $light-grey-black !important;
    }
    &--danger{
        border-color: $common-danger !important;
    }
    &--success{
        border-color: $common-success !important;
    }
    &--info{
        border-color: $common-info !important;
    }
    &--warning{
        border-color: $common-warning !important;
    }
    &--purple{
        border-color: $common-purple !important;
    }
    &--blue{
        border-color: $common-blue !important;
    }
    &--white{
        border-color: $common-white !important;
    }
    &--transparent{
        border-color: transparent !important;
    }
    &--2{
        border-width: 2px !important;
    }
    &--3{
        border-width: 3px !important;
    }
    &--7{
        border-width: 7px !important;
    }
    &--dashed{
        border-style: dashed !important;
    }
    &--solid{
        border-style: solid !important;
    }
    &--light-blue{
        border-color: $lightBlue !important;
    }
    &--blue-darkLight{
        border-color: $lightBlue !important;
    }
    &-no-focus{
        &:focus{
            border-color: $inputBorder;
        }
    }
}