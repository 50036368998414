.collapse {
  &-wrap {
    border-bottom: 1px solid $light-grey-black;
    padding-bottom: 20px;
    padding-top: 20px;
    gap: 12px;
    display: flex;
    flex-direction: column;
  }
  &-heading {
    // font-size: 18px;
    // font-weight: 600;

    svg {
      transform: rotate(180deg);
    }
    &[aria-expanded="true"] {
      svg {
        transform: rotate(270deg);
      }
    }
    &.graph-collapse-heading {
      svg {
        transform: none;
      }
    }
  }
  &-content {
    font-size: 14px;
    padding-left: 25px;
  }
}
.appointment-collapse {
  &-head {
    &.collapsed{
      span {
        svg {
          transform: rotate(0deg);
          transition: all 0.5s ease-in-out;
        }
      }
    }
    span {
      svg {
        transform: rotate(180deg);
        transition: all 0.5s ease-in-out;
      }
    }
  }
}
.collapse {
  &-email-heading {
    background-color: $greyblack;
    border: 1px solid $shadowBorder;
    svg {
      transform: rotate(180deg);
      transition: all 0.5s ease-in-out;
    }
    &[aria-expanded="true"] {
      svg {
        transform: rotate(270deg);
        transition: all 0.5s ease-in-out;
      }
    }
  }
  &-email-content {
    .email-option {
      &-item {
        &:hover,
        &.active {
          color: $common-blue;
        }
      }
    }
  }
}
