.btn {
  min-height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-weight: 500;
  width: auto;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    opacity: 0.8;
  }
  &:first-child {
    &.active {
      color: $common-white !important;
    }
  }
  &--secondary {
    background-color: $btnSecondary;
    color: $blueWhite;
    border-color: $btnBorder;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background-color: $btnSecondary !important;
      color: $blueWhite !important;
      border-color: $btnBorder !important;
    }
    &:disabled {
      background-color: $btnSecondary !important;
      color: $common-blue-800 !important;
      border-color: $btnBorder !important;
    }
    &-danger {
      color: $common-danger;
      border-color: $common-danger;
      background-color: $btnSecondary;

      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        background-color: $btnSecondary !important;
        color: $common-danger !important;
        border-color: $common-danger !important;
      }
    }
  }
  &--primary {
    background-color: $common-blue;
    color: $common-white;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      // background-color: $common-blue  !important;
      // color: $common-white  !important;
      border: 1px solid $shadowBorder !important;
    }
  }
  &--grey {
    background-color: $common-grey;
    color: $common-white;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background-color: $common-grey !important;
      color: $common-white !important;
    }
  }
  &--light-grey-black {
    background-color: $light-grey-black;
    color: $middleBlack;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background-color: $light-grey-black !important;
      color: $middleBlack !important;
    }
  }
  &--border {
    background-color: transparent;
    border-style: dashed;
    // border-color: $inputBorder;
    border-color: $tab-2-border;
    color: $common-blue;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background-color: transparent;
      border-color: $common-blue !important;
      color: $common-blue !important;
    }
  }
  &--red {
    background-color: $common-danger-100;
    color: $common-danger;
    border-color: $common-danger;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background-color: $common-danger-100 !important;
      color: $common-danger !important;
      border-color: $common-danger !important;
    }
  }
  &--warning {
    &-outline {
      background-color: $common-warning-100;
      color: $common-warning;
      border-color: $common-warning;
      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        background-color: $common-warning-100 !important;
        color: $common-warning !important;
        border-color: $common-warning !important;
      }
    }
  }
  &--success {
    background-color: $common-success-100;
    color: $common-success;
    border: none;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background-color: $common-success-100 !important;
      color: $common-success !important;
      border: none;
    }
    &-outline {
      background-color: $common-success-100;
      color: $common-success;
      border-color: $common-success;
      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        background-color: $common-success-100 !important;
        color: $common-success !important;
        border-color: $common-success !important;
      }
    }
  }
  &--white {
    background-color: $whiteBlackBox;
    color: $common-blue;
    border-color: $border;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background-color: $whiteBlackBox !important;
      color: $common-blue !important;
      border-color: $border !important;
    }
  }
  &--sm {
    min-height: 32px;
  }
  &--md {
    min-height: 36px;
  }
  &--lg {
    min-height: 48px;
  }
  &--dashed {
    border-style: dashed;
  }
  &--sm-full {
    @media screen and (max-width: 767px) {
      width: 100% !important;
    }
  }
  &--md-full {
    @media screen and (max-width: 1024px) {
      width: 100% !important;
    }
  }
  &--bold {
    font-weight: 700 !important;
  }
}
// button {
//   &:disabled {
//     pointer-events: unset !important;
//     cursor: not-allowed;
//     background-color: $light-black !important;
//     border-color: $light-black !important;
//     color: $white !important;
//   }
// }
