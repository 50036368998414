.custom-tab {
  // gap: 1.5rem;
  gap: 1rem;
  &-item {
    // color: $black-600;
    color: $common-grey;
    padding: 10px 16px;
    &:hover {
      color: $common-blue;
    }
    &.active {
      border: 1px solid $shadowBorder;
      box-shadow: 0px 4px 20px 0px $common-shadow;
      background-color: $whiteBlackBox;
      font-weight: 600;
      border-radius: 8px;
      color: $black;
    }
  }
}
.vertical {
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 767px) {
    flex-direction: row;
  }
  .custom-tab-item {
    color: $common-grey;
    // padding: 8px 0px 8px 8px;
    padding: 8px 8px;
    &:hover {
      color: $common-blue;
    }
    &.active {
      color: $common-blue;
      background-color: $greyblack;
    }
  }
}
.tab-2 {
  .custom-tab-item {
    &.active {
      color: $black;
      background-color: $blue-2-100;
      font-weight: 400;
      border-radius: 24px;
      border-color: $tab-2-border;
    }
  }
}
.tab-3 {
  gap: 9px;

  .custom-tab-item {
    font-size: 12px;
    opacity: 0.6;
    color: $black;
    &.active {
      color: $common-blue;
      background-color: $blackGrey;
      font-weight: 600;
      border-radius: 8px;
      border-color: $border;
      border-width: 2px;
      box-shadow: 0px 4.156px 20.778px 0px rgba(11, 45, 121, 0.06);
      opacity: 1;
      font-size: 16px;
    }
  }
}
