.form-check {
  padding-left: 0px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;

  /* Custom radio buttons */
  input[type="radio"] + label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-right: 15px;
    // font-size: 14px;
    &:before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 14px;
      position: absolute;
      top: 2px;
      left: 0;
      color: $common-white;
      border: 1px solid $common-grey;
      background-color: transparent;

      border-radius: 50%;
    }
  }

  input[type="radio"] {
    display: none !important;
    *display: inline;

    &:checked + label:after {
      content: "";
      display: block;
      position: absolute;
      top: 6px;
      left: 4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $common-blue;
      border: 1px solid $common-blue;
    }
  }
  &.radio--sm{
    input[type="radio"] + label {
      padding-left: 20px;
      &:before {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
    }
    input[type="radio"] {
      &:checked + label:after {
        top: 5px;
        left: 3px;
        width: 8px;
        height: 8px;
      }
    }
  }
  &.radio--lg{
    input[type="radio"] + label {
      &:before {
        width: 22px;
        height: 22px;
      }
    }
    input[type="radio"] {
      &:checked + label:after {
        width: 14px;
        height: 14px;
      }
    }
  }
  // End Custom radio buttons

  /* Custom checkbox */
  input[type="checkbox"] + label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-right: 15px;
    // font-size: 14px;
    font-weight: normal;

    &:before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 14px;
      position: absolute;
      top: 3px;
      left: 0;
      border: 1px solid $common-grey;
      background-color: transparent;
      border-radius: 4px;
    }
  }

  input[type="checkbox"] {
    display: none !important;
    *display: inline;

    &:checked + label:after {
      // content: "✔";
      content: "\2714";
      font-size: 12px;
      line-height: 14px;
      color: $common-white;
      border: 1px solid $common-blue;
      background-color: $common-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 3px;
      left: 0px;
      width: 18px;
      height: 18px;
      border-radius: 4px;
    }
  }
  // css add for mobile tick color end
    input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  // css add for mobile tick color end
  // End Custom checkbox
  &-table {
    /* Custom checkbox */
    input[type="checkbox"] + label {
      padding-left: 5px;
      margin-right: 5px;
    }
    /* Custom radio buttons */
    input[type="radio"] + label {
      padding-left: 5px;
      margin-right: 5px;
    }
  }
  &.form-switch{
    .form-check-label{
      font-size: 14px;
      cursor: pointer;
    }
    input[type=checkbox]{
      display: inline !important;
      margin-left: 12px;
      margin-top: 0;
      background-color: $light-grey-black;
      width: 44px;
      height: 22px;
      border: none;
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
      box-shadow: none;
      cursor: pointer;

      &:checked{
        background-color: $common-success;
      }
    }
  }
  &.checkbox--sm{
    input[type="checkbox"] {
      &:checked + label:after {
        font-size: 8px;
        width: 14px;
        height: 14px;
        top: 1px;
      }
    }

    input[type="checkbox"] + label {
      padding-left: 22px;
      &:before {
        width: 14px;
        height: 14px;
        margin-right: 10px;
        top: 1px;
      }
    }
  }
}
